import { MeetingRoomFinderService } from './meeting-room-finder.service';
import { Component, OnInit } from '@angular/core';
import { ConnectWebSocket, SendWebSocketMessage } from '@ngxs/websocket-plugin';
import { Store} from '@ngxs/store';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  constructor(private store: Store, public meetingRoomFinderService: MeetingRoomFinderService) {
  }

  ngOnInit(): void{
    this.store.dispatch(new ConnectWebSocket());
    this.meetingRoomFinderService.selectedRooms.subscribe((result)=>{
      this.sendMessage(result);
    });
  }

  sendMessage(message: number[]) {
    const event = new SendWebSocketMessage({
      type: 'viewedMeetingRooms',
      message
    });
    this.store.dispatch(event);
  }
}
