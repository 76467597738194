import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'sample',
    pathMatch: 'full'
  },
  {
    path: 'meetings-and-conferences',
    loadChildren: () => import('./meetings-and-conferences/meetings-and-conferences.module').then( m => m.MeetingsAndConferencesPageModule)
  },
  {
    path: 'offices',
    loadChildren: () => import('./offices/offices.module').then( m => m.OfficesPageModule)
  },
  {
    path: 'co-working',
    loadChildren: () => import('./co-working/co-working.module').then( m => m.CoWorkingPageModule)
  },
  {
    path: 'sample',
    loadChildren: () => import('./sample/sample.module').then( m => m.SamplePageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./shared/event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
