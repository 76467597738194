import { Store } from '@ngxs/store';
import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LiberoofficeroomsState } from './state/liberoofficerooms.state';

@Injectable({
  providedIn: 'root'
})
export class MeetingRoomFinderService {

  public kafkaMessages$: Observable<MbscCalendarEvent[]> =  this.store.select(LiberoofficeroomsState.messages);

  public selectedRooms: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public cart: BehaviorSubject<MbscCalendarEvent[]> = new BehaviorSubject([]);
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient, private store: Store) {
    this.httpClient = httpClient;
      this.init();
   }
    getMeetingRoomKey(room: LiberoMeetingroomsAtZieher | string) {
      const indexOfRoom = Object.values(LiberoMeetingroomsAtZieher).indexOf(room as unknown as LiberoMeetingroomsAtZieher);
      const key = Object.keys(LiberoMeetingroomsAtZieher)[indexOfRoom];
      return key;
    }


    public async reserveEventInMeetingRoom(event: MbscCalendarEventData){
      const key = this.getMeetingRoomKey(event.resource+ '');
      const eventMessageBody = await this.apiCallPersistMeetingroomReservation(event);
      await this.httpClient.post('http://localhost:8083/reserveRoom/'+key,eventMessageBody).toPromise();
    }

    public async deleteMeetingroomReservation(event: MbscCalendarEvent){
        this.cart.next( this.cart.value.filter((cartEvent)=>cartEvent.id !== event.id));
    }

    public async putMeetingroomReservationToCart(event: MbscCalendarEvent){
      event.event = await this.apiCallPersistMeetingroomReservation(event.event);
      this.putReservedEventIntoCart(event);
    }

    async apiCallPersistMeetingroomReservation(event: MbscCalendarEventData){
      console.log(event);
      return this.httpClient.post<MbscCalendarEventData>('http://localhost:8081/reservations', event,{
        responseType: 'json'
      } ).toPromise();
    }


    private async putReservedEventIntoCart(event: MbscCalendarEvent){
      const newCart = this.cart.getValue();
      newCart.push(event);
      this.cart.next(newCart);
    }

   private init(){
      const result: number[] =this.selectedRooms.value;
      Object.keys(LiberoMeetingroomsAtZieher).forEach((_,key)=>result.push(key));
      this.selectedRooms.next(result);
   }
}

export enum LiberoMeetingroomsAtZieher {
  konferenzraumMediaspree='Tacheles - 22pax',
  meetingraumMediaspree='Else7 - 8pax',
  konferenzraumKudamm='Checkpoint Charlie - 18pax',
  meetingraumKudamm1='Quadriga - 8pax',
  meetingraumKudamm2='Goldelse - 6pax',
  meetingraumBCW='Foursquare 4² - 4pax'
}
