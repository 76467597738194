import { Injectable } from '@angular/core';
import { MbscCalendarEvent } from '@mobiscroll/angular';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AddMessageAction } from './liberoofficerooms.actions';

export class LiberoofficeroomsStateModel {
  public messages: MbscCalendarEvent[];
}

@State<LiberoofficeroomsStateModel>({
  name: 'liberoofficerooms',
  defaults: {
    messages: []
  }
})
@Injectable()
export class LiberoofficeroomsState {
  @Selector()
  static messages(state: LiberoofficeroomsStateModel): MbscCalendarEvent[] {
    return state.messages;
  }

  @Action(AddMessageAction)
  add({ getState, setState }: StateContext<LiberoofficeroomsStateModel>,  action: AddMessageAction) {
    const state = getState();
    setState({ messages: [ ...state.messages, action.message ] });
  }
}
