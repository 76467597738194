import { MbscModule } from '@mobiscroll/angular';
import { StateService } from 'src/app/state.service';
import { LiberoofficeroomsState } from './state/liberoofficerooms.state';
import { NgxsModule } from '@ngxs/store';
import { FormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxsWebsocketPluginModule } from '@ngxs/websocket-plugin';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializeApp1(appInitService: StateService) {
  // eslint-disable-next-line arrow-body-style
  return (): Promise<any> => appInitService.init();

}

@NgModule({
  declarations: [AppComponent],
  imports: [
    MbscModule,
    FormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot([LiberoofficeroomsState]),
    NgxsWebsocketPluginModule.forRoot({
      url: 'ws://localhost:8084/websocket'
    })
    ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: APP_INITIALIZER,useFactory: initializeApp1, deps: [StateService], multi: true},
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
