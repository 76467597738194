import { MbscCalendarEvent } from '@mobiscroll/angular';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { v4 as uuidv4 } from 'uuid';

const sessionIdKey = 'cartId';
@Injectable({
  providedIn: 'root'
})
export class StateService {

  liberoLocation = LiberoLocations.totalBerlin;
  liberoProduct = LiberoProducts.offices;
  currentEvent: MbscCalendarEvent = {};
  sessionId;

  public liberoLocations = new Map<LiberoLocations,string>([
    [LiberoLocations.berlinKu,'Berlin Kurfürstendamm'],
    [LiberoLocations.berlinMS,'Berlin Mediaspree'],
    [LiberoLocations.berlinUhl,'Berlin City West'],
    [LiberoLocations.totalBerlin,'All of Berlin']
  ]);

  public liberoProductActions = new Map<LiberoProducts,string>([
    [LiberoProducts.coWorking,'Book Timeslot now'],
    [LiberoProducts.franchise,'Show Requestform'],
    [LiberoProducts.meetingsAndConferences,'Book your room now'],
    [LiberoProducts.offices,'Request Propsal'],
    [LiberoProducts.virtualOffice,'Buy Business Adress and Services']
  ]);

  constructor(private router: Router) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((navigationEnd)=>{
      switch (navigationEnd.url){
        case '/meetings-and-conferences':
          this.liberoProduct = LiberoProducts.meetingsAndConferences;
          break;
        case '/offices':
          this.liberoProduct = LiberoProducts.offices;
          break;

        case '/virtual-office':
          this.liberoProduct = LiberoProducts.virtualOffice;
          break;

        case '/coworking':
          this.liberoProduct = LiberoProducts.coWorking;
          break;
        default:
          this.liberoProduct = LiberoProducts.offices;
          break;
      }
    });
  }

  async init(){
    try {
      const value = await Preferences.get(({ key: sessionIdKey }));
      if (value) {
        this.sessionId = value;
      } else {
        this.sessionId = uuidv4();
      }
      console.log('init finished');
      return true;
    } catch (error) {
      return console.log(error);
    }
  }

  setLiberoLocation(value: LiberoLocations) {
    this.liberoLocation = value;
  }

  setLiberoProductViewed(value: LiberoProducts){
    this.liberoProduct = value;
  }

  getCurrentProductActionName(){
    return this.liberoProductActions.get(this.liberoProduct);
  }

  setPagePosition( pagePosition: number, currentPageName: string) {
    localStorage.setItem('currentPageName',currentPageName);
    localStorage.setItem('pagePosition',pagePosition+'');
  }

  getPagePosition(currentPageName: string): any {
    if(currentPageName===localStorage.getItem('currentPageName')){
      return localStorage.getItem('pagePosition');
    } else {
      this.setPagePosition(0,currentPageName);
      return 0;
    }

  }

  setCurrentEvent(currentEvent: MbscCalendarEvent){
    this.currentEvent = currentEvent;
  }

  getCurrentEvent(){
    return this.currentEvent;
  }

  getSessionId(){
    return this.sessionId;
  }
}

export enum LiberoLocations {
  totalBerlin,
  berlinKu,
  berlinUhl,
  berlinMS
}

export enum LiberoProducts {
  offices,
  virtualOffice,
  meetingsAndConferences,
  coWorking,
  franchise
}

